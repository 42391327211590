import { AnimatePage } from "Atoms/AnimatePage";
import { Container } from "Atoms/Container";
import { SeoHead } from "Atoms/SeoHead";
import Typed from "react-typed";

const IndexPage = () => {
  const talkAbout = [
    "Eccommerce",
    "Admin Panels",
    "RESTful APIs",
    "Institucional Websites",
    "Software Enginnering",
    "IT Consultancy",
  ];

  return (
    <AnimatePage>
      <SeoHead
        title="Hey, we are the Plug With Us"
        description=" We design and develop Web & Custom Software Development, delivering
		scalable systems and web applications. You share your idea. We get it
		done. Bespoke Software Development from Conception to Delivery."
        keywords={[
          "Eccommerce",
          "Admin Panels",
          "Software Consulting",
          "RESTful APIs",
          "Software Enginnering",
          "IT Consultancy",
        ]}
      />
      <Container>
        <h1 className="headline mt-4 pb-2 md:mt-20 text-3xl md:text-5xl lg:text-6xl">
          Hey, we are the Plug With Us 👋
        </h1>
        <p className="headline mt-4 pb-2 md:mt-6 text-xl md:text-3xl lg:text-4xl">
          We are launching our new identity 🚀
        </p>
        <p className="my-8 text-lg">
          We design and develop Web & Custom Software Development, delivering
          scalable systems and web applications. You share your idea. We get it
          done. Bespoke Software Development from Conception to Delivery.
        </p>
        <p className="my-8 text-lg">
          You can talk to us about{" "}
          <Typed
            loop
            typeSpeed={80}
            backSpeed={20}
            strings={talkAbout}
            smartBackspace
            backDelay={1000}
            loopCount={0}
            showCursor
            cursorChar="|"
          />
          .
        </p>
        <p className="hidden md:block">
          P.S. We specialize in creating software for clients ranging from
          individuals and small-businesses all the way to large enterprise
          corporations. What would you do if you had a software expert company
          available at your fingertips?
        </p>
      </Container>
    </AnimatePage>
  );
};

export default IndexPage;
